@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.background {
  background-color: #1b1b1e;
}

.centered {
  height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1b1b1e;
}
